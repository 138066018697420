import React, { useContext } from "react";
import { useTheme } from "@mui/material/styles";
import { Trans } from "react-i18next";
import { UserContext } from "../../../store/UserContext";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  CardMedia,
  Divider,
  IconButton,
  ButtonBase,
  Box,
  Alert,
  Stack,
  Button,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";

import { getContrastColors } from "../../../story-components/components";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DateRangeIcon from "@mui/icons-material/DateRange";
import TableRestaurantRoundedIcon from "@mui/icons-material/TableRestaurantRounded";

function chiamaNumero(numero) {
  const link = `tel:${encodeURIComponent(numero)}`;
  window.open(link, "_blank");
}

function apriWhatsApp(whatsapp) {
  const link = `https://wa.me/39${encodeURIComponent(whatsapp)}`;
  window.open(link, "_blank");
}

const BusinessCardBackground = ({ image, name, info, ...rest }) => {
  return (
    <Card
      sx={{
        borderRadius: "0px 0px 5px 5px",
        display: "flex",
        backgroundImage: `url("${image}")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
      {...rest}
    >
      <Box
        sx={{ boxShadow: 4 }}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "min-content",
          backgroundColor: "rgba(0,0,0,0.6)", // sfondo semi trasparente scuro
          color: "white",
        }}
      >
        <CardContent>{info}</CardContent>
      </Box>
    </Card>
  );
};

const BusinessCardSide = ({
  image,
  name,
  backgroundColor,
  info,
  onClickName,
  ...rest
}) => {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      {...rest}
      sx={{ backgroundColor: backgroundColor }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          width: "max-content",
          zIndex: 2,
        }}
      >
        <Box sx={{ padding: "5px", maxWidth: "50vw", width: "max-content" }}>
          {info}
        </Box>
      </Box>
      {image && (
        <Card
          sx={{
            boxShadow: 4,
            borderRadius: "0px 0px 0px 10px",
            maxWidth: "350px",
            maxHeight: "350px",
          }}
        >
          <img
            onClick={onClickName}
            src={image}
            alt={name}
            loading="lazy"
            style={{
              borderRadius: "0px 0px 0px 0px",
              maxHeight: "350px",
              maxWidth: "350px",
              height: "100%",
              width: "100%",
              objectFit: "cover", // Questo mantiene l'aspetto di CardMedia
            }}
          />
        </Card>
      )}
    </Stack>
  );
};

const BusinessCardVertical = ({
  image,
  name,
  color,
  backgroundColor,
  info,
  onClickName,
  ...rest
}) => {
  return (
    <Box
      sx={{
        borderRadius: "0px",
        paddingBottom: "5px",
        boxShadow: 1,
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "white",
        }}
      >
        {image && (
          <img
            onClick={onClickName}
            src={image}
            alt={name}
            style={{
              width: "auto",
              maxWidth: "100vw",
              objectFit: "cover",
              maxHeight: "45vh",
            }}
            loading="lazy"
          />
        )}

        <Box sx={{ padding: "0px 5px 0px 5px" }}>{info}</Box>
      </Box>
    </Box>
  );
};

const TopCircleImageBusinessCard = ({
  image,
  name,
  color,
  backgroundColor,
  info,
  onClickName,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={0}
      sx={{ width: "100%" }}
    >
      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{
          display: "flex",
          width: "100%",
          marginBottom: "-1px",
          borderRadius: "0px",
          overflow: "hidden",
          backgroundColor: backgroundColor,
        }}
      >
        <img
          onClick={onClickName}
          component="img"
          loading="lazy"
          style={{ width: 150, height: 150, borderRadius: "50%" }}
          src={image}
        />
        {info}
      </Stack>
      <Box
        sx={{
          width: "100%",
          height: "30px",
          borderRadius: "0px 0px 50% 50%",
          backgroundColor: backgroundColor,
        }}
      ></Box>
    </Stack>
  );
};

function formatPhoneNumber(input) {
  try {
    // Rimuovi spazi, trattini e caratteri non numerici
    const cleanInput = input.replace(/[^0-9]/g, '');

    // Verifica la lunghezza minima e massima del numero
    if (cleanInput.length < 7 || cleanInput.length > 15) {
      throw new Error('Invalid length'); // Numero non valido
    }

    let formattedNumber;

    // Logica di formattazione basata sulla lunghezza del numero
    if (cleanInput.length === 9) {
      // Numeri mobili italiani di 9 cifre (es. 347987654)
      formattedNumber = cleanInput.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3');
    } else if (cleanInput.length === 10) {
      // Numeri fissi italiani o mobili con prefissi (es. 0331123456)
      formattedNumber = cleanInput.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3');
    } else if (cleanInput.length === 11) {
      // Numeri internazionali senza il "+" (es. 393401234567)
      formattedNumber = cleanInput.replace(/(\d{3})(\d{4})(\d{4})/, '+$1 $2 $3');
    } else if (cleanInput.length > 11) {
      // Numeri internazionali completi con prefissi (es. +39 123 456 789)
      formattedNumber = cleanInput.replace(/(\d{2,4})(\d{3})(\d{4})/, '+$1 $2 $3');
    } else {
      // Numeri brevi o particolari (es. interni aziendali)
      formattedNumber = cleanInput.replace(/(\d{3})(\d+)/, '$1 $2');
    }
    console.log('formatter:',formatPhoneNumber)
    return formattedNumber;
  } catch (error) {
    // Se c'è un errore, restituisci il numero originale
    console.warn('Error formatting number:', error.message, 'Input:', input);
    return input;
  }
}


function BusinessCard({
  image,
  name,
  type,
  city,
  cap,
  address,
  latitude,
  longitude,
  phone,
  whatsapp,
  facebook,
  instagram,
  isOpen,
  onClickName,
  navigateToLandingPage = false,
  onClickWeAreClosed,
  table_booking = false,
}) {
  const theme = useTheme();
  const { localData } = useContext(UserContext);
  let navigate = useNavigate();
  function RenderIsOpen({ isOpen }) {
    if (isOpen === false) {
      return (
        <ButtonBase
          onClick={onClickWeAreClosed}
          disabled={!navigateToLandingPage}
        >
          <Alert icon={<EventBusyIcon fontSize="inherit" />} severity="warning">
            <Trans>Siamo chiusi</Trans>
          </Alert>
        </ButtonBase>
      );
    }
  }

  function InfoCard({ color, ...rest }) {
    return (
      <Stack
        direction="column"
        spacing={0}
        {...rest}
        divider={
          <Divider
            orientation="horizontal"
            flexItem
            sx={{ color: color, width: "100%" }}
            color={color}
          />
        }
      >
        <Box sx={{ display: "flex", padding: "0px 10px 0px 10px" }}>
          <Typography
            variant="h5"
            color={color}
            onClick={onClickName}
            sx={{ width: "100%" }}
          >
            {name}
          </Typography>
        </Box>

        {address && address !== "" && (
          <Box
            sx={{ display: "inline-flex", alignItems: "center", width: "100%" }}
          >
            <IconButton
              onClick={() => {
                window.open(
                  `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`,
                  "_blank"
                );
              }}
            >
              <LocationOnIcon style={{ color: color, width: "30px" }} />
            </IconButton>
            <ButtonBase
              sx={{
                display: "inline-flex",
                alignItems: "center",
                borderRadius: "5px",
              }}
              onClick={() => {
                window.open(
                  `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`,
                  "_blank"
                );
              }}
            >
              <Typography
                variant="subtitle1"
                align="left"
                color={color}
                sx={{ width: "100%" }}
              >
                {city} {cap} - {address}
              </Typography>
            </ButtonBase>
          </Box>
        )}
        <Stack direction="row" spacing={0}>
          {name && localData.plan_value > 1 && (
            <IconButton onClick={onClickName}>
              <DateRangeIcon style={{ color: color, width: "30px" }} />
            </IconButton>
          )}
          {phone !== undefined && phone !== "" && phone !== null && (
            <Box sx={{ display: "inline-flex", alignItems: "center" }}>
              <IconButton onClick={() => chiamaNumero(phone)}>
                <LocalPhoneIcon style={{ color: color, width: "30px" }} />
              </IconButton>
              <ButtonBase
                style={{ width: "fit-content" }}
                onClick={() => chiamaNumero(phone)}
              >
                <Typography variant="subtitle1" color={color}>
                  {formatPhoneNumber(phone)}
                </Typography>
              </ButtonBase>
            </Box>
          )}
        </Stack>

        {whatsapp !== undefined && whatsapp !== "" && whatsapp !== null && (
          <Stack
            sx={{ display: "inline-flex", alignItems: "center" }}
            direction="row"
          >
            <IconButton onClick={() => chiamaNumero(whatsapp)}>
              <SmartphoneIcon style={{ color: color, width: "30px" }} />
            </IconButton>

            <IconButton onClick={() => apriWhatsApp(whatsapp)}>
              <WhatsAppIcon style={{ color: color, width: "30px" }} />
            </IconButton>

            <ButtonBase
              style={{ width: "fit-content" }}
              onClick={() => chiamaNumero(whatsapp)}
            >
              <Typography variant="subtitle1" color={{ color: color }}>
                {formatPhoneNumber(whatsapp)}
              </Typography>
            </ButtonBase>
          </Stack>
        )}

        {(facebook !== undefined && facebook !== "" && facebook !== null) |
          (instagram !== undefined &&
            instagram !== "" &&
            instagram !== null) && (
          <Stack direction="row">
            {facebook !== undefined && facebook !== "" && facebook !== null && (
              <IconButton
                style={{ width: "fit-content", color: color }}
                href={facebook}
              >
                <FacebookIcon style={{ color: color, width: "30px" }} />
              </IconButton>
            )}
            {instagram !== undefined &&
              instagram !== "" &&
              instagram !== null && (
                <IconButton
                  style={{ width: "fit-content", color: color }}
                  href={instagram}
                >
                  <InstagramIcon style={{ color: color, width: "30px" }} />
                </IconButton>
              )}
          </Stack>
        )}

        {!isOpen && (
          <Box sx={{ padding: "5px" }}>
            <RenderIsOpen isOpen={isOpen} />
          </Box>
        )}

        {table_booking && (
          <Button
            style={{ height: "100%" }}
            startIcon={<TableRestaurantRoundedIcon />}
            onClick={() => navigate("/" + localData.local_id + "/booking_form")}
            disabled={!localData.table_booking}
            fullWidth
            variant="contained"
          >
            <Trans>Prenota un tavolo</Trans>
          </Button>
        )}
      </Stack>
    );
  }
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  if (type === "side") {
    return (
      <BusinessCardSide
        image={image}
        name={name}
        onClickName={onClickName}
        info={<InfoCard color={theme.palette.text.background} />}
      />
    );
  } else if (type === "background") {
    return (
      <BusinessCardBackground
        image={image}
        name={name}
        info={
          <InfoCard
            color={"white"}
            style={{ width: "max-content", maxWidth: "50vw" }}
          />
        }
      />
    );
  } else if (type === "vertical") {
    if (isSmallScreen) {
      return (
        <BusinessCardVertical
          image={image}
          onClickName={onClickName}
          name={name}
          info={<InfoCard color={theme.palette.text.background} />}
        />
      );
    } else {
      return (
        <BusinessCardSide
          image={image}
          name={name}
          onClickName={onClickName}
          info={<InfoCard color={theme.palette.text.background} />}
        />
      );
    }
  } else if (type === "circle") {
    return (
      <TopCircleImageBusinessCard
        image={image}
        name={name}
        onClickName={onClickName}
        backgroundColor={theme.palette.primary.main}
        info={
          <InfoCard
            color={getContrastColors("#000000", theme.palette.primary.main)}
          />
        }
      />
    );
  } else {
    return (
      <BusinessCardSide
        image={image}
        name={name}
        info={<InfoCard color={theme.palette.text.background} />}
      />
    );
  }
}

export default BusinessCard;
